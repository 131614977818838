#approach-section .graph {
  margin-top: 35px;
}
#approach-section .graph p.subheading {
  margin-bottom: 22px;
}
#approach-section .graph ul {
  list-style: none;
  padding: 0;
  margin-bottom: 50px;
  width: 75%;
}
@media (max-width: 992px) {
  #approach-section .graph ul {
    width: 100%;
  }
}
#approach-section .graph ul li {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 17px 0 28px 0;
}
#approach-section .graph ul li .planning-icon {
  margin-right: 30px;
}
#approach-section .graph ul li .planning-icon img {
  width: 65px;
  height: 65px;
  padding: 2px;
  border: 2px solid rgba(165,97,181,0.27);
  border-radius: 50%;
}
#approach-section .graph ul li h5 {
  text-transform: uppercase;
  font-family: "Nunito Sans";
  font-size: 19px;
  color: #864696;
  font-weight: 700;
  display: inline-block;
  margin: 0 0 8px 0;
}
#approach-section .graph ul li p {
  font-size: 18px;
}
@media (max-width: 992px) {
  #approach-section .graph ul li p {
    width: 90%;
  }
}
@media (max-width: 767px) {
  #approach-section .graph ul li p {
    width: initial;
  }
}
#approach-section .graph .circles {
  margin-top: 55px;
  margin-bottom: 10px;
}
@media (max-width: 992px) {
  #approach-section .graph .circles .circle-containter:nth-child(1),
  #approach-section .graph .circles .circle-containter:nth-child(2) {
    margin-bottom: 50px;
  }
}
#approach-section .graph .circles .circle-containter .circle {
  height: 155px;
  width: 155px;
  background: rgba(165,97,181,0.1);
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
}
#approach-section .graph .circles .circle-containter .circle img {
  position: absolute;
  width: 95px;
  height: 95px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#approach-section .graph .circles .circle-containter .circle:after {
  content: "";
  width: 98px;
  height: 2px;
  background-color: rgba(165,97,181,0.1);
  position: absolute;
  top: 50%;
  left: 100%;
}
@media (max-width: 1200px) {
  #approach-section .graph .circles .circle-containter .circle:after {
    width: 55px;
  }
}
@media (max-width: 992px) {
  #approach-section .graph .circles .circle-containter .circle:after {
    display: none;
  }
}
#approach-section .graph .circles .circle-containter:last-child .circle:after {
  content: none;
}
#approach-section .graph .circles .circle-containter p {
  margin-top: 27px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  color: #864696;
}
